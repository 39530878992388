import React from "react";
import '../../App.css';
import AdvGallery from "../AdvGallery";
import Footer from "../Footer";

export default function Adventure() {
    return (
        <>
            <h1 className="adventure">ADVENTURE</h1>;
            <AdvGallery />
            <Footer />
        </>
    )
};