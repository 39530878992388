import React from "react";
import '../../App.css';
import CardWork from "../CardWork";
import Footer from "../Footer";

export default function Experience() {
    return (
        <>
            <h1 className="experience">MY CAREER</h1>
            <CardWork />
            <Footer />
        </>
    )
};